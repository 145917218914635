import React, { useState, useEffect, memo } from 'react';
import '../../mobile/serviceShop/ServiceShopDetailCss.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowCircleLeft } from '@mui/icons-material';
import ApiCollections from '../../services/ApiCollections';
import { ApiRequest } from '../../services/common/ApiRequest';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../components/commons/Loading';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ServiceShopDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { shopId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    getStoreShop();
  }, []);

  const [loading, setLoading] = useState(false);
  const [shopDetail, setShopDetail] = useState();
  const getStoreShop = async () => {
    setLoading(true);
    let requestObj = {
      method: 'get',
      url: ApiCollections.serviceShopDetail + '/' + shopId,
    };
    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response?.status === 200) {
      setShopDetail(response?.data?.data);
    }
  };

  const goback = () => {
    navigate(-1);
  };
  const goToServiceShop = (id) => {
    navigate(`/ServiceShop/${id}`);
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      {loading && <Loading open={loading} />}
      <div className="serviceShopDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t('service_shop_details')}</div>
        </div>
        {shopDetail && (
          <div className="storeHeader">
            <div className="logo">
              <img src={shopDetail?.photo_url} className="image" />
            </div>
            <div className="nameDiv">
              <div className="name">{shopDetail?.name}</div>
              <div className="address">{shopDetail?.phone_numbers}</div>
              <div className="address">
                {shopDetail?.address}&nbsp;,&nbsp;
                {shopDetail?.is_entire_region
                  ? shopDetail?.region_name
                  : shopDetail?.city_name}
              </div>
            </div>
          </div>
        )}
        <div className="storeblank">
          <div className="title">{t('Services')}</div>
          <div className="box">
            {shopDetail?.merketplace_services.map((service, index) => (
              <div
                className="name"
                key={index}
                onClick={() => goToServiceShop(service.id)}
              >
                <strong>.&nbsp;</strong>
                {service?.service_type}
              </div>
            ))}
          </div>
        </div>
        {/* <div className="storeblank">
          <div className="title">{t('Services Area')}</div>
          <div className="box">
            {shopDetail?.merketplace_services_arr.map((area) => (
              <div className="name" key={area}>{area}</div>
            ))}
          </div>
        </div> */}

        <div className="storeblank">
          <div className="w-full h-auto">{shopDetail?.description}</div>
        </div>

        <div className="imageDiv"></div>
      </div>
    </>
  );
};

export default memo(ServiceShopDetail);
