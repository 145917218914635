import React, { memo, useEffect, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Star } from '@mui/icons-material';
import { MenuItem, Select } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import '../serviceShop/ServiceShopCss.scss';
import ApiCollections from '../../../services/ApiCollections';
import { ApiRequest } from '../../../services/common/ApiRequest';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/commons/Loading';

const ServiceShopCard = ({}) => {
  const { t } = useTranslation();
  const { categoryID } = useParams();
  const [storeShop, setStoreShop] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [serviceCategory, setServiceCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(categoryID || '');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setLoading(true);
    getServiceCategory();
    fetchLocations();
  }, []);
  useEffect(() => {
    getServiceStore(true);
  }, [selectedCategory, selectedLocation]);
  // Fetch Service Categories
  const getServiceCategory = useCallback(async () => {
    try {
      const response = await ApiRequest({
        method: 'get',
        url: ApiCollections.serviceCategoryList,
        params: { page: 1 },
      });

      if (response.data?.data) {
        setServiceCategory(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching service categories:', error);
    }
  }, []);
  // Fetch Locations
  const fetchLocations = useCallback(async () => {
    try {
      const response = await ApiRequest({
        method: 'get',
        url: ApiCollections.regionsApi,
      });

      if (response.data?.code === 200) {
        setLocations(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  }, []);

  const getServiceStore = useCallback(
    async (reset = false) => {
      try {
        if (reset) {
          setPage(1);
          setStoreShop([]);
        }

        setLoading(true);
        const response = await ApiRequest({
          method: 'get',
          url: ApiCollections.serviceShops,
          params: {
            page: reset ? 1 : page,
            marketplace_service_id: selectedCategory || '',
            region_id: selectedLocation || '',
          },
        });

        setLoading(false);

        if (response.data?.data) {
          setPageCount(response?.data?.meta?.last_page);
          setStoreShop((prev) =>
            reset ? response.data.data : [...prev, ...response.data.data]
          );
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching store shops:', error);
      }
    },
    [page, selectedCategory, selectedLocation]
  );

  return (
    <>
      {loading && <Loading open={loading} />}

      {/* Category Filter */}
      <div className="categoryBox">
        {serviceCategory.map((category) => (
          <div
            key={category.id}
            className={`category ${
              Number(selectedCategory) === category.id ? 'active' : ''
            }`}
            onClick={() => setSelectedCategory(category.id)}
          >
            {category.service_type_en}
          </div>
        ))}
      </div>
      {/* Location Filter */}
      <div className="locationFilter">
        <div className="name">{t('Region')}&nbsp;:&nbsp;</div>
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
          displayEmpty
          className="customSelect"
        >
          <MenuItem
            value=""
            sx={{
              fontSize: '14px !important',
            }}
          >
            {t('All')}
          </MenuItem>
          {locations &&
            locations.map((reginItem, regionIndex) => (
              <MenuItem
                key={regionIndex}
                value={reginItem.region_id}
                sx={{
                  fontSize: '14px !important',
                }}
              >
                {reginItem.region_name_en}
              </MenuItem>
            ))}
        </Select>
      </div>
      <div className="w-full h-auto pt-[20px]">
        {storeShop.length > 0 ? (
          <div className="w-full h-auto grid grid-cols-3 gap-x-[20px] gap-y-[20px]">
            {storeShop?.map((osItem, osIndex) => (
              <div
                key={osIndex}
                className="shopDiv"
                onClick={() => navigate(`/ServiceShopDetail/${osItem.id}`)}
              >
                <div className="imgDiv">
                  <img
                    src={osItem?.photo_url}
                    className="imgCustom w-full h-full"
                  />
                </div>
                <div className="divright">
                  <div className="title">{osItem.name}</div>
                  <div className="address">{osItem.region_name}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="noData">{t('No Service Shop Available')}</div>
        )}

        {storeShop.length > 0 && (
          <InfiniteScroll
            dataLength={storeShop.length}
            next={() => setPage((prevPage) => prevPage + 1)}
            hasMore={page < pageCount}
            loader={
              pageCount >= page ? (
                <div className="text-xl w-full py-4 text-center">
                  {t('loading')}
                </div>
              ) : (
                <div className="text-xl w-full py-4 text-center">
                  {t('End of Service Shop')}
                </div>
              )
            }
          ></InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default memo(ServiceShopCard);
