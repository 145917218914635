import React, { memo, useEffect, useState } from 'react';
import ApiCollections from '../../services/ApiCollections';
import { ApiRequest } from '../../services/common/ApiRequest';
import { motion } from 'framer-motion';

import 'animate.css';
import './serviceShop.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const ServiceShops = () => {
  const navigate = useNavigate();
  const [serviceCategory, setServiceCategory] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getServiceCategory();
  }, []);
  // const viewMore = () => {
  //   navigate('ServiceShopList');
  // };

  const getServiceCategory = async () => {
    let obj = {
      method: 'get',
      url: ApiCollections.serviceCategoryList,
      params: {
        page: '1',
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.data) {
      setServiceCategory(response.data);
    }
  };

  const goToServiceShop = (id) => {
    navigate(`/ServiceShop/${id}`);
  };

  return (
    <div className="service-stores-wrapper">
      <div className="title-div">
        <div className="title">{t('Service Shops')}</div>
        {/* <div className="view-more" onClick={() => viewMore()}>
          {t('seeMore')}
        </div> */}
      </div>

      <div className="w-full h-auto grid grid-cols-7 gap-x-[20px] my-[30px]">
        {serviceCategory?.slice(0, 7).map((osItem, osIndex) => (
          <motion.div
            key={osIndex}
            className="w-full h-[150px] relative group osImgG rounded-md overflow-hidden cursor-pointer"
            onClick={() => goToServiceShop(osItem.id)}
            initial={{ opacity: 0, y: 60 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true, margin: '-40px' }}
          >
            <div className="w-full h-full">
              <img
                src={osItem?.photo_url}
                className="w-full h-full object-cover osImg group-hover:scale-125 duration-300"
              />
            </div>
            <span className="officialName text-white absolute w-full h-auto bottom-0 left-0 bg-[rgba(0,0,0,.5)] duration-300 py-1 text-center">
              {osItem?.service_type_en}gfljgfd gkfgnffkgf gfgnfkg f
            </span>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default memo(ServiceShops);
