import React, { useState, useEffect, memo, useCallback } from 'react';
import '../../mobile/serviceShop/ServiceShopCss.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuItem, Select } from '@mui/material';
import { ArrowCircleLeft } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import ApiCollections from '../../services/ApiCollections';
import { ApiRequest } from '../../services/common/ApiRequest';
import Loading from '../../components/commons/Loading';
import { useTranslation } from 'react-i18next';

const ServiceShop = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { categoryID } = useParams();

  // State Management
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(categoryID || '');
  const [locations, setLocations] = useState([]);
  const [storeShop, setStoreShop] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [serviceCategory, setServiceCategory] = useState([]);

  // Fetch Service Categories
  const getServiceCategory = useCallback(async () => {
    try {
      const response = await ApiRequest({
        method: 'get',
        url: ApiCollections.serviceCategoryList,
        params: { page: 1 },
      });

      if (response.data?.data) {
        setServiceCategory(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching service categories:', error);
    }
  }, []);

  // Fetch Service Shops
  const fetchData = useCallback(
    async (reset = false) => {
      try {
        if (reset) {
          setPage(1);
          setStoreShop([]);
        }

        setLoading(true);
        const response = await ApiRequest({
          method: 'get',
          url: ApiCollections.serviceShops,
          params: {
            page: reset ? 1 : page,
            marketplace_service_id: selectedCategory || '',
            region_id: selectedLocation || '',
          },
        });

        setLoading(false);

        if (response.data?.data) {
          setPageCount(response?.data?.meta?.last_page);
          setStoreShop((prev) =>
            reset ? response.data.data : [...prev, ...response.data.data]
          );
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching store shops:', error);
      }
    },
    [page, selectedCategory, selectedLocation]
  );

  // Fetch Locations
  const fetchLocations = useCallback(async () => {
    try {
      const response = await ApiRequest({
        method: 'get',
        url: ApiCollections.regionsApi,
      });

      if (response.data?.code === 200) {
        setLocations(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  }, []);

  // Initial Data Fetch
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchLocations();
    getServiceCategory();
  }, [fetchLocations, getServiceCategory]);

  // Update Data on Category or Location Change
  useEffect(() => {
    fetchData(true);
  }, [selectedCategory, selectedLocation, fetchData]);

  return (
    <>
      {loading && <Loading open={loading} />}
      <div className="storeAllDiv">
        {/* Header */}
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => navigate(-1)} />
          <div className="title">{t('service_shop')}</div>
        </div>

        {/* Category Filter */}
        <div className="categoryBox">
          {serviceCategory.map((category) => (
            <div
              key={category.id}
              className={`category ${
                Number(selectedCategory) === category.id ? 'active' : ''
              }`}
              onClick={() => setSelectedCategory(category.id)}
            >
              {category.service_type_en}
            </div>
          ))}
        </div>

        {/* Location Filter */}
        <div className="locationFilter">
          <div className="name">{t('Region')}&nbsp;:&nbsp;</div>
          {/* <select
            className="customSelect"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            <option value="">{t('All')}</option>
            {locations.map((location) => (
              <option key={location.region_id} value={location.region_id}>
                {location.region_name_en}
              </option>
            ))}
          </select> */}
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            displayEmpty
            className="customSelect"
          >
            <MenuItem
              value=""
              sx={{
                fontSize: '3.6vw !important',
                lineHeight: '7vw !important',
              }}
            >
              {t('All')}
            </MenuItem>
            {locations &&
              locations.map((reginItem, regionIndex) => (
                <MenuItem
                  key={regionIndex}
                  value={reginItem.region_id}
                  sx={{
                    fontSize: '3.6vw !important',
                    lineHeight: '7vw !important',
                  }}
                >
                  {reginItem.region_name_en}
                </MenuItem>
              ))}
          </Select>
        </div>

        {/* Store List */}
        <ul className="storeFloor">
          <li className="floorItem">
            <div className="shopBox">
              {storeShop.length > 0 ? (
                storeShop.map((storeItem) => (
                  <div
                    key={storeItem.id}
                    className="shopDiv"
                    onClick={() =>
                      navigate(`/ServiceShopDetail/${storeItem.id}`)
                    }
                  >
                    <div className="imgDiv">
                      <img src={storeItem.photo_url} alt={storeItem.name} />
                    </div>
                    <div className="divright">
                      <div className="title">{storeItem.name}</div>
                      <div className="address">{storeItem.region_name}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="noData">{t('No Service Shop Available')}</div>
              )}
            </div>
          </li>
        </ul>

        {/* Infinite Scroll */}
        {storeShop.length > 0 && (
          <InfiniteScroll
            dataLength={storeShop.length}
            next={() => setPage((prevPage) => prevPage + 1)}
            hasMore={page < pageCount}
            loader={<div className="loading">{t('loading')}</div>}
          />
        )}
      </div>
    </>
  );
};

export default memo(ServiceShop);
