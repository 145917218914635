import React, { useContext, useState, useEffect, memo } from 'react';
import { Cancel } from '@mui/icons-material';
import './pCompareCss.scss';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'swiper/css/navigation';
import 'swiper/css';

import { NavigateContext } from '../context';
import ApiCollections from '../../services/ApiCollections';
import { ApiRequest } from '../../services/common/ApiRequest';
import { updatecompareProducts } from '../../reducers/appState';

const CompareProductItem = ({ product }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { compareProducts } = useSelector((state) => state.appState);
  useEffect(() => {
    window.scrollTo(0, 0);
    getProductDetail();
    setImg('');
  }, [product]);

  const [forThumbnail, setForThumbnail] = useState([]);
  const [showImg, setShowImg] = useState([]);
  const [img, setImg] = useState('');

  const [detailproduct, setProductDetail] = useState([]);
  const [shortdes, setShortdes] = useState('');

  const getProductDetail = async () => {
    setProductDetail(product);
    setForThumbnail(product.product_file);
    setShowImg(product.product_file[0].photo);
    setShortdes(product.product_description);
  };

  const navigate = useContext(NavigateContext);

  const newArray = [];
  let newData = [...forThumbnail];
  while (newData.length > 0) newArray.push(newData.splice(0, 5));

  const goDetail = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
    } else {
      navigate(`/products/product-detail/${product_id}`);
    }
  };
  const removeCompareProduct = async (e) => {
    e.stopPropagation();
    let productId = product?.product_id;
    if (compareProducts && compareProducts?.length) {
      if (compareProducts.find((product) => product.product_id == productId)) {
        let dataCompare = compareProducts.filter(
          (productID) => productID.product_id !== productId
        );
        dispatch(updatecompareProducts(dataCompare));

        toast.error('Product Remove from Compare!', {
          className: 'toastColor',
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  return (
    <div
      className="product-compare-wrapper"
      onClick={() =>
        goDetail(
          detailproduct.product_id,
          detailproduct.item_status,
          detailproduct.item_status
        )
      }
    >
      <div
        className="deleteDiv"
        onClick={(e) => removeCompareProduct(e, detailproduct.product_id)}
      >
        <Cancel className="deleteBtn" />
      </div>
      <div className="img-container">
        <img
          src={img ? img : showImg && showImg}
          className="comimage"
          tabIndex={0}
        />
      </div>

      <div className="pt-3">
        <div className="w-[100%] h-auto">
          <div className="product-title">{detailproduct.product_name}</div>
          <div className="price mt-[5px]">
            <div>
              {detailproduct.product_promotion_price !== 0 ? (
                <>
                  <span className="current-price">
                    <span className="money !text-xl text-priceColor">
                      {detailproduct.product_promotion_price}ks
                    </span>
                  </span>
                  <span className="discounted-price">
                    <s className="money ">{detailproduct.product_price}ks</s>
                  </span>
                </>
              ) : (
                <span className="current-price">
                  <span className="money !text-xl text-priceColor">
                    {detailproduct.product_price}ks
                  </span>
                </span>
              )}
            </div>
            <span>
              {detailproduct.stock == 0 ? (
                <p className="text-mainColor text-bold text-[14px] font-bold">
                  {t('OutOfStock')}
                </p>
              ) : (
                <p className="text-mainColor text-bold text-[14px] font-bold">
                  {t('InStock')}
                </p>
              )}
            </span>
          </div>

          <p className="text-secondColor text-[14px] font-bold mt-[5px]">
            {t('ShopName')}&nbsp;: &nbsp;
            <span className="text-black font-normal text-[12px]">
              {detailproduct.shop_name}
            </span>
          </p>

          {/* <div className="cart-btn-div gap-x-4">
            <div
              onClick={removeCompareProduct}
              className="add-compare-btn !text-[16px] !font-bold"
            >
              {t('Remove From Compare')}
            </div>
          </div> */}
        </div>

        {shortdes && (
          <div className="w-full h-auto mt-[5px]">
            <p className="text-[16px] text-mainColor font-bold">
              {t('Summary')}
            </p>
            <ReactQuill
              theme="bubble"
              value={shortdes}
              readOnly="true"
              className="text-[12px]"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(CompareProductItem);
