import React, { useState, useEffect, memo } from 'react';
import '../../mobile/compare/CompareProductCard.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import { useLocation } from 'react-router-dom';
import { Cancel } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import { updatecompareProducts } from '../../reducers/appState';
// swiper package

const CompareProductCard = ({ detailproduct }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const compareProducts = useSelector(
    (state) => state.appState.compareProducts
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goDetail = (id) => {
    navigate(`/products/product-detail/${id}`);
  };
  // console.log('detailproduct', detailproduct);
  const removeCompareProduct = (e, id) => {
    e.stopPropagation();
    const updatedProducts = compareProducts.filter(
      (product) => product.product_id !== id
    );
    dispatch(updatecompareProducts(updatedProducts));

    toast.error('Product Removed from Compare!', {
      className: 'toastColor',
      draggable: true,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <>
      <ToastContainer autoClose={3000} />{' '}
      <div
        className="productCompareDiv"
        onClick={() => goDetail(detailproduct.product_id)}
      >
        <Cancel
          className="deleteBtn"
          onClick={(e) => removeCompareProduct(e, detailproduct.product_id)}
        />
        {detailproduct.product_file && (
          <div>
            {detailproduct.product_file && (
              <div className="proPhoto">
                <img
                  src={detailproduct?.product_file?.[0]?.photo}
                  className="proImg"
                />
              </div>
            )}
          </div>
        )}

        <div className="describe">
          <p className="descriptionFn">{detailproduct?.product_name}</p>
          <p className="price">
            {detailproduct?.product_promotion_price !== 0 ? (
              <span className="ori">
                {detailproduct?.product_promotion_price} ks &nbsp;&nbsp;
                <s className="sdel">{detailproduct?.product_price}ks</s>
              </span>
            ) : (
              <span className="ori">{detailproduct?.product_price} ks</span>
            )}
            <span>
              {detailproduct.stock == 0 ? (
                <span className="outStock">{t('OutOfStock')}</span>
              ) : (
                <span className="outStock">{t('InStock')}</span>
              )}
            </span>
          </p>

          <div className="shopName">
            <span className="afix">{t('ShopName')}&nbsp;:</span>
            &nbsp;
            <span className="asin">{detailproduct.shop_name}</span>
          </div>

          <div className="desDetail">
            <span className="nameTitle">{t('Summary')}</span>
            <ReactQuill
              theme="bubble"
              value={detailproduct?.product_description}
              readOnly="true"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CompareProductCard);
