import React, { memo } from 'react';

import { Container, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'swiper/css/navigation';
import Lottie from 'lottie-react';

import Wrapper from '../Wrapper';
import nodata from '../../assets/nodata.json';
import './pCompareCss.scss';
import CompareProductItem from './CompareProductItem';
import { updatecompareProducts } from '../../reducers/appState';

const CompareProductList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { compareProducts } = useSelector((state) => state.appState);
  const removeAllCompareProduct = async () => {
    dispatch(updatecompareProducts([]));
    toast.error('All Product Remove from Compare!', {
      className: 'toastColor',
      draggable: true,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <Wrapper>
      <Container>
        <div className="compareProductPc">
          {compareProducts.length > 0 ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 20,
                }}
              >
                {compareProducts.map((productDetail, index) => (
                  <CompareProductItem
                    key={productDetail?.product_id}
                    product={productDetail}
                  />
                ))}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '30px',
                  marginBottom: '30px',
                }}
              >
                <Button
                  onClick={removeAllCompareProduct}
                  variant="contained"
                  color="error"
                  style={{
                    width: '30%',
                    height: '40px',
                  }}
                >
                  Remove All Product
                </Button>
              </div>
            </div>
          ) : (
            <div className="noDataform">
              <div className="text">{t('No Compare Product')}</div>
              <div className="iconWrap">
                <Lottie animationData={nodata} loop={true} id="lot" />
              </div>
            </div>
          )}
        </div>
        <ToastContainer draggable={false} autoClose={3000} />
      </Container>
    </Wrapper>
  );
};

export default memo(CompareProductList);
