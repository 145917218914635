import React, { memo, useEffect, useState } from 'react';
import Wrapper from '../../Wrapper';
import { Container, ListItemButton, ListItemText } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ServiceShopCss.scss';
import ApiCollections from '../../../services/ApiCollections';
import { ApiRequest } from '../../../services/common/ApiRequest';
import Loading from '../../../components/commons/Loading';
import ListItem from '@mui/material/ListItem';

const ServiceShopDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
    getStoreShop();
  }, []);

  const { shopId } = useParams();

  const [shopDetail, setShopDetail] = useState();
  const [loading, setLoading] = useState(false);

  const getStoreShop = async () => {
    setLoading(true);
    let requestObj = {
      method: 'get',
      url: ApiCollections.serviceShopDetail + '/' + shopId,
    };
    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response?.status === 200) {
      setShopDetail(response?.data?.data);
    }
  };
  const goToServiceShop = (id) => {
    navigate(`/ServiceShop/${id}`);
  };

  return (
    <Wrapper>
      {loading && <Loading open={loading} />}
      <Container>
        <div className="w-full h-auto mt-[70px]">
          <div className="w-[100%] flex items-center">
            <div className="w-[100px] h-[100px]">
              <img
                src={shopDetail?.photo_url}
                className="w-full h-full rounded-md"
              />
            </div>
            <div className="nameDiv">
              <div className="name">{shopDetail?.name}</div>
              <div className="address">{shopDetail?.phone_numbers}</div>
              <div className="address">
                {shopDetail?.address}&nbsp;,&nbsp;
                {shopDetail?.is_entire_region
                  ? shopDetail?.region_name
                  : shopDetail?.city_name}
              </div>
            </div>
          </div>

          <div className="w-full h-auto">
            <div className="w-full flex pt-5 pb-2">
              <div>
                <p
                  className="text-[22px] text-mainColor font-bold
                "
                >
                  {t('Services')}
                </p>
              </div>
            </div>
            <div className="box">
              {shopDetail?.merketplace_services.map((service, index) => (
                <div
                  className="name"
                  key={index}
                  onClick={() => goToServiceShop(service.id)}
                >
                  <strong>.&nbsp;</strong>
                  {service?.service_type}
                </div>
              ))}
            </div>
          </div>

          <div className="w-full h-auto mt-3 pb-3">
            {shopDetail?.description}
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default memo(ServiceShopDetail);
