import React, { memo } from 'react';
import Wrapper from '../Wrapper';
import './CompareProduct.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import Lottie from 'lottie-react';
import nodata from '../../assets/nodata.json';
import { useTranslation } from 'react-i18next';
import CompareProductCard from './CompareProductCard';
import Button from '@mui/material/Button';
import { updatecompareProducts } from '../../reducers/appState';

const CompareProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { compareProducts } = useSelector((state) => state.appState);

  const removeAllCompareProduct = async () => {
    dispatch(updatecompareProducts([]));
    toast.error('All Product Remove from Compare!', {
      className: 'toastColor',
      draggable: true,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <Wrapper>
      <ToastContainer autoClose={3000} />
      <div className="Cart" style={{ paddingBottom: '8vh' }}>
        <div className="header" id="topHeader">
          <div className="title">{t('Compare Product')}</div>
        </div>
        {compareProducts?.length > 0 ? (
          <div>
            {compareProducts?.map((product) => (
              <>
                <CompareProductCard
                  key={product?.product_id}
                  detailproduct={product}
                />
              </>
            ))}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '7vw',
              }}
            >
              <Button
                onClick={removeAllCompareProduct}
                variant="contained"
                color="error"
                style={{
                  width: '90%',
                  height: '7vh',
                }}
              >
                Remove All Product
              </Button>
            </div>
          </div>
        ) : (
          <div className="noDataform">
            <div className="text">{t('No Compare Product')}</div>
            <div className="iconWrap">
              <Lottie animationData={nodata} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default memo(CompareProduct);
