import * as React from 'react';
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Badge,
} from '@mui/material';
import {
  Home,
  Category,
  ShoppingCart,
  Person,
  CompareArrows,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import '../../mobile/footer/FooterCss.scss';
import { checkedNavActive } from '../../reducers/bottomNav';

const Footer = () => {
  const location = useLocation();
  const { totalCount } = useSelector((state) => state.cart);
  const { compareProducts } = useSelector((state) => state.appState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [urlValue, setUrlValue] = React.useState(null);
  const { isActive } = useSelector((state) => state.nav);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    dispatch(checkedNavActive({ isActive: newValue }));
    navigate(newValue);
  };
  React.useEffect(() => {
    setUrlValue(location.pathname);
  }, [location]);

  return (
    <div>
      <Box
        style={{
          width: '100%',
          height: '13vw',
          bottom: '0',
          background: '#fff',
          position: 'fixed',
          fontSize: '3.5vw',
        }}
      >
        <BottomNavigation
          style={{ height: '13vw' }}
          showLabels
          value={urlValue ? urlValue : isActive}
          onChange={handleChange}
        >
          <BottomNavigationAction
            style={{ maxWidth: '1024px' }}
            label={t('Home')}
            value="/"
            icon={
              <Home
                style={{ width: '7vw', height: '7vw', fontSize: '3.5vw' }}
              />
            }
          />
          <BottomNavigationAction
            style={{ maxWidth: '1024px' }}
            label={t('Category')}
            value="/Categories"
            icon={
              <Category
                style={{ width: '7vw', height: '7vw', fontSize: '3.5vw' }}
              />
            }
          />
          <BottomNavigationAction
            style={{ maxWidth: '1024px' }}
            label={t('Compare')}
            value="/CompareProduct"
            icon={
              <Badge badgeContent={compareProducts?.length} color="error">
                <CompareArrows
                  style={{ width: '7vw', height: '7vw', fontSize: '3.5vw' }}
                />
              </Badge>
            }
          />
          <BottomNavigationAction
            style={{ maxWidth: '1024px' }}
            label={t('Cart')}
            value="/ShoppingCart"
            icon={
              <Badge badgeContent={totalCount} color="error">
                <ShoppingCart
                  style={{ width: '7vw', height: '7vw', fontSize: '3.5vw' }}
                />
              </Badge>
            }
          />
          <BottomNavigationAction
            style={{ maxWidth: '1024px' }}
            label={t('Account')}
            value="/PersonalCenter"
            icon={<Person style={{ width: '7vw', height: '7vw' }} />}
          />
        </BottomNavigation>
      </Box>
    </div>
  );
};

export default Footer;
